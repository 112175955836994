/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import url('https://fonts.googleapis.com/css2?family=Langar&family=Oswald:wght@200;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,800;1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,300;0,400;0,600;0,800;1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,400;1,100;1,500;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,700;1,400&display=swap');
ion-content,
body {
    --background: none;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    --background: var(--ion-color-light) !important;
    background: var(--ion-color-light) !important;
}

ion-item,
ion-list {
    --background: none !important;
    background: none !important;
}

// ----- TIPO DE LETRAS ----- //
h3,
h4,
h5,
h2,
h1,
p,
button,
input,
span,
ion-label {
    font-family: var(--font) !important;
}

p {
    color: var(--ion-color-dark) !important;
    font-family: var(--font) !important;
    font-size: 14px;
    margin: 0px;
}

var {
    font-family: var(--font);
}

.aviso {
    font-size: 14px !important;
    ;
    font-family: var(--font);
    text-align: center !important;
}

.tipoletra {
    font-family: var(--font) !important;
}

.normal {
    color: var(--ion-color-dark) !important;
    font-family: var(--font) !important;
    font-size: 14px !important;
    white-space: normal;
    font-weight: normal !important;
    line-height: 15px !important;
}

li {
    color: var(--ion-color-dark) !important;
    font-family: var(--font) !important;
    font-size: 14px;
    margin-left: 30px;
}

.subtitulo {
    color: var(--ion-color-dark) !important;
    font-family: var(--font) !important;
    font-size: 18px !important;
    line-height: 28px !important;
    font-weight: bold !important;
    white-space: normal;
}

.titulo2 {
    color: var(--ion-color-dark) !important;
    font-family: var(--font) !important;
    font-size: 16px !important;
    line-height: 15px !important;
    font-weight: bold !important;
    white-space: normal;
}

.titulo {
    color: var(--secundario) !important;
    font-family: var(--font) !important;
    font-size: 25px !important;
    line-height: 30px !important;
    ;
    font-weight: bold !important;
    white-space: normal;
}

.encabezado {
    color: var(--color-encabezado) !important;
    font-family: var(--font) !important;
    font-size: 30px !important;
    line-height: 35px !important;
    ;
    font-weight: bold !important;
    white-space: normal;
}

.background-light {
    background: var(--ion-color-light);
    --background: var(--ion-color-light);
}

.primario {
    color: var(--primario) !important;
}

.primario-bg {
    background: var(--primario-fixed) !important;
    --background: var(--primario-fixed) !important;
}

.primario-fixed {
    color: var(--primario-fixed) !important;
}

.primario-contraste {
    color: var(--primario-contraste) !important;
}

.primario-c-bg {
    background: var(--primario-contraste) !important;
    --background: var(--primario-contraste) !important;
}

.secundario {
    color: var(--secundario) !important;
}

.secundario-bg {
    background: var(--secundario-fixed) !important;
    --background: var(--secundario-fixed) !important;
}

.secundario-fixed {
    color: var(--secundario-fixed) !important;
}

.secundario-contraste {
    color: var(--secundario-contraste) !important;
}

.secundario-c-bg {
    background: var(--secundario-contraste) !important;
    --background: var(--secundario-contraste) !important;
}

.terciario {
    color: var(--terciario) !important;
}

.terciario-bg {
    background: var(--terciario-fixed) !important;
    --background: var(--terciario-fixed) !important;
}

.terciario-fixed {
    color: var(--terciario-fixed) !important;
}

.terciario-contraste {
    color: var(--terciario-contraste) !important;
}

.terciario-c-bg {
    background: var(--terciario-contraste) !important;
    --background: var(--terciario-contraste) !important;
}

.background-light {
    background: var(--ion-color-light) !important;
    --background: var(--ion-color-light) !important;
}

.light {
    color: var(--ion-color-light) !important;
}

.background-dark {
    background: var(--ion-color-dark) !important;
    --background: var(--ion-color-dark) !important;
}

.dark {
    color: var(--ion-color-dark) !important;
}

.white {
    color: white !important;
}

.black {
    color: black !important;
}

.number {
    font-family: var(--number) !important;
}

// --- menu ----- //
.popoverMenu {
    // --max-width: 300px;
    --width: 90%;
    // --max-height: 30%;
    --background: var(--bms);
    // --box-shadow: none;
}

.popoverEnlace {
    // --max-width: 300px;
    // --max-height: 30%;
    --background: #dee2e6;
    --box-shadow: none;
}

.popoverLogin {
    --max-width: 350px;
    --width: 350px;
    --max-height: 500px;
}

.none {
    --background: none !important;
    background: none !important;
}

.tooltipClass {
    font-family: var(--font);
}

.zoom-main span img {
    max-height: 350px !important;
}

.mat-tab-label {
    height: 100% !important;
    min-width: 200px !important;
}

.swiper-pagination-bullet {
    width: 15px;
    height: 15px;
    text-align: center;
    line-height: 15px;
    font-size: 12px;
    color: #000;
    opacity: 1;
    background: rgba(0, 0, 0, 0.2);
    border: 1px solid black;
}

.swiper-pagination-bullet-active {
    color: #fff;
    background: var(--secundario);
    border: 1px solid black;
}

.text1line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.text2line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.text3line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.popoverSearch {
    --max-width: 400px;
    --width: 400px;
    --max-height: 500px;
}

.popOverGestor{
  --max-width: 400px;
  --width: 400px;
  --max-height: 500px;
  position: absolute;
  top: -20%;
}

.popoverRegistrarse {
    --max-width: 350px;
    --width: 350px;
}

.button-next {
    --background: var(--secundario) !important;
    max-width: 250px;
    margin-bottom: 10px;
    color: var(--secundario-contraste) !important;
    margin-left: auto;
    border-radius: 8px;
}

.mat-step-label {
    padding: 16px 0 0 0;
    text-align: center;
    width: unset !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.size-font-menu {
    font-size: 14px !important;
}

@media (max-width: 1416px) {
    .size-font-menu {
        font-size: 13px !important;
    }
}

@media (max-width: 1366px) {
    .size-font-menu {
        font-size: 11px !important;
    }
}

@media (max-width: 1170px) {
    .size-font-menu {
        font-size: 10px !important;
    }
}

@media (max-width: 500px) {
    .popoverSearch {
        --max-width: 95%;
        --width: 95%;
        --max-height: 500px;
    }
    .mat-vertical-content {
        padding: 5px !important;
    }
}

.boton-primario {
    // --background: #f09419;
    color: black;
    border: solid;
    border-width: 2px;
    border-color: var(--secundario) !important;
    padding: 5px 13px 5px 13px;
    border-radius: 5px;
    background: #f9f4ed91;
    text-transform: none;
    font-weight: bold;
}

.popoverSelectCrudFast {
    --max-width: 400px;
    --width: 400px;
    --max-height: 300px;
}

mat-expansion-panel-header:hover {
    background: none !important;
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
    color: var(--secundario) !important;
}

.scrollX {
    overflow-x: auto;
    overflow-y: hidden;
}

mat-step-header {
    margin-top: 15px !important;
}

.tel-input {
    width: 100%;
    background: none;
    padding: 10px 0px 10px 55px;
    border: 1px solid var(--primario);
    font-size: 16px;
}

ion-segment-button {
    text-transform: capitalize !important;
    letter-spacing: inherit !important;
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
    background-color: var(--ion-color-light) !important;
}

.mat-vertical-stepper-header {
    height: 100% !important
}

.picker-opts {
    max-width: 60px !important;
    min-width: 60px !important;
}

ion-button {
    text-transform: unset !important;
    letter-spacing: unset !important
}

.s2_footer_about p {
    color: var(--primario-contraste) !important;
}
.s2_footer_about  h1 {
    color: var(--primario-contraste) !important;
}
.s2_footer_about  h2 {
    color: var(--primario-contraste) !important;
}
.s2_footer_about  h3 {
    color: var(--primario-contraste) !important;
}
.s2_footer_about  h4 {
    color: var(--primario-contraste) !important;
}
.s2_footer_about  h5 {
    color: var(--primario-contraste) !important;
}
.s2_footer_about  h6 {
    color: var(--primario-contraste) !important;
}
.s2_footer_about a {
    color: var(--primario-contraste) !important;
}

.alert-title {
    color: var(--ion-color-dark) !important;
}

.cke_wysiwyg_frame, .cke_wysiwyg_div, mat-expansion-panel {
        background-color: var(--ion-color-light) !important;
}


// estilos chatbot
.popoverChatBot {
    --max-width: 500px;
    --width: 500px;
    --max-height: 500px;
    overflow: hidden;
}

@media (max-width: 500px) {
    .popoverChatBot {
        --max-width: 500px;
        --width: 300px;
        --max-height: 500px;
        overflow: hidden;

    }
}

ion-card {
    background: none !important;
    --background: none !important;
    border: solid var(--ion-color-dark) 1px !important;
}

mat-datepicker-toggle {
    color: var(--ion-color-dark) !important;
}

.mat-form-field-label {
    color: var(--ion-color-dark) !important;
}

.popover-content {
    overflow-x: hidden !important;
}
